<template>
  <main class=" w-100">
    <div class="containerimg" style="height: 95vh;">
      <img src="@/assets/images/aboutlanding.png" alt="Snow" style="width:100%;height: 100%;object-fit: cover;">
      <div class="center-center text-center p-5">
        <h2 class="text-white text-uppercase">{{ $t('AboutSkyreach') }}</h2>
        <p class="lead">
          {{ $t('Aboutlanding_P') }}
        </p>
        <button type="button" class="btn btn-outline-light me-2 text-uppercase"
          style="border-radius: 0 !important;">
          <i class="fas fa-phone me-2"></i>{{ $t('ContactUs') }}
        </button>
      </div>
    </div>
  </main>
  <section class="p-5 pb-0 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">

      <div class=" text-align-left ">
        <div class="row">
          <div class="col-sm  d-flex align-items-center justify-content-center p-5">
            <div>
              <h6 class="text-uppercase"><i class="fas fa-circle me-2 text-orange"></i><b class="text-lightblue">{{
          $t('AboutSkyreach') }}</b>
              </h6>
              <p style="text-align: justify;
  text-justify: inter-word;">{{ $t('AboutSkyreach_P') }}</p>
            </div>
          </div>
          <div class="col-sm d-flex justify-content-end">
            <img src="@/assets/images/thereach_antena.jpg" alt="Snow"
              style="width:100%;height: 100%;object-fit: cover;">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="p-5 pt-0 pb-0 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">
      <div class=" text-align-left ">
        <div class="row">
          <div class="col-sm d-flex justify-content-end">
            <img src="@/assets/images/kapall.png" alt="Snow" style="width:100%;height: 100%;object-fit: cover;">
          </div>
          <div class="col-sm  d-flex align-items-center justify-content-center p-5">
            <div>
              <h6 class="text-uppercase"><i class="fas fa-circle me-2 text-orange"></i><b class="text-lightblue">{{
          $t('WhatWeDo_title') }}</b>
              </h6>
              <p style="text-align: justify;
  text-justify: inter-word;">{{ $t('WhatWeDo_P') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="p-5 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">
      <h6 class="text-uppercase"><i class="fas fa-circle me-2 text-orange"></i><b class="text-lightblue">{{
          $t('SkyreachHistory') }}</b>
      </h6>
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-2">
        <div class="col ">
          <div class="row g-0  overflow-hidden flex-md-row mb-4 border-blue">
            <div class="col p-4 d-flex flex-column position-static">
              <h3 class="mb-0 text-blue">2012 - 2013</h3>
              <p class="card-text mb-auto">{{ $t('2012_2013') }}</p>
            </div>
            <div class=" d-none d-lg-block" style="height: 30vh">
              <img src="@/assets/images/2012_2013.png" class="w-100 h-100" style="object-fit: cover;" alt="">
            </div>
          </div>
        </div>
        <div class="col ">
          <div class="row g-0  overflow-hidden flex-md-row mb-4 border-blue">
            <div class="col p-4 d-flex flex-column position-static">
              <h3 class="mb-0 text-blue">2016</h3>
              <p class="card-text mb-auto">{{ $t('2016') }}<br><br></p>
            </div>
            <div class=" d-none d-lg-block" style="height: 30vh">
              <img src="@/assets/images/2016.png" class="w-100 h-100" style="object-fit: cover;" alt="">
            </div>
          </div>
        </div>
        <div class="col ">
          <div class="row g-0  overflow-hidden flex-md-row mb-4 border-blue">
            <div class="col p-4 d-flex flex-column position-static">
              <h3 class="mb-0 text-blue">2017</h3>
              <p class="card-text mb-auto">{{ $t('2017') }}<br><br></p>
            </div>
            <div class=" d-none d-lg-block" style="height: 30vh;">
              <img src="@/assets/images/2017.png" class="w-100 h-100" style="object-fit: cover;" alt="">
            </div>
          </div>
        </div>
        <div class="col ">
          <div class="row g-0  overflow-hidden flex-md-row mb-4 border-blue">
            <div class="col p-4 d-flex flex-column position-static">
              <h3 class="mb-0 text-blue">2018</h3>
              <p class="card-text mb-auto">{{ $t('2018') }}<br><br><br></p>
            </div>
            <div class=" d-none d-lg-block" style="height: 30vh;">
              <img src="@/assets/images/2018.png" class="w-100 h-100" style="object-fit: cover;" alt="">
            </div>
          </div>
        </div>
        <div class="col ">
          <div class="row g-0  overflow-hidden flex-md-row mb-4 border-blue">
            <div class="col p-4 d-flex flex-column position-static">
              <h3 class="mb-0 text-blue">2019</h3>
              <p class="card-text mb-auto">{{ $t('2019') }}
                <br>
                <br>
                <br>
              </p>
            </div>
            <div class=" d-none d-lg-block" style="height: 30vh;">
              <img src="@/assets/images/2019.png" class="w-100 h-100" style="object-fit: cover;" alt="">
            </div>
          </div>
        </div>
        <div class="col ">
          <div class="row g-0  overflow-hidden flex-md-row mb-4 border-blue">
            <div class="col p-4 d-flex flex-column position-static">
              <h3 class="mb-0 text-blue">2020 - 2024</h3>
              <p class="card-text mb-auto">{{ $t('2020_2024') }}</p>
            </div>
            <div class=" d-none d-lg-block" style="height: 30vh">
              <img src="@/assets/images/2020_2024.png" class="w-100 h-100" style="object-fit: cover;" alt="">
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>


</template>