<template>
    <footer class="text-center pt-5  text-lg-start bg-darkblue text-muted">
  
      <!--ADRESSES-->
      <section class="">
        <div class="container text-center text-md-start mt-2">
          <div class="row mt-3">
            <div class="col-md-6 text-white col-lg-4 col-xl-4 mx-auto mb-4">
                
                <img src="@/assets/images/sr_iso_white_120x120.png" class="img-fluid" alt="logo_skyreach" srcset="">
            </div>
     
            <div class="col-md-6 col-lg-4 col-xl-3 mx-auto mb-4 text-white">
                <img src="@/assets/images/logo_skyreach.svg" class="footer_logo mb-5" alt="logo_skyreach" srcset="">
              <p>
               <b class="text-uppercase">{{$t('HeadOffice')}}</b><br>
               Hermina Tower
                Lantai 20, Suite A/B
                Jl. HBR Motik No. 4, Kemayoran
                DKI Jakarta - 10610
              </p>
              <p>
                <b class="text-uppercase">{{$t('CallCenter')}}</b><br>
                {{$t('Phone')}} : +6221-3100100 <br>
                {{$t('Email')}} : info@skyreach.id
              </p>
            </div>
           
          </div>
        </div>
      </section>
  
      <!--SOCIAL MEDIA-->
      <section class="bg-blue  text-white d-flex justify-content-center justify-content-between p-4 border-bottom">
        <div>
            © Copyright {{ year }} Skyreach - All Rights Reserved
        </div>
        <div>
          <a href="https://www.facebook.com/skyreach.id/" target="_blank" class="me-4 text-reset contact_icon">
            <i class="fab fa-facebook-square"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCPB8S0ZrFnZnJj16x4P3_lQ" target="_blank"
            class="me-4 text-reset contact_icon">
            <i class="fab fa-youtube"></i>
          </a>
          <a href="https://www.instagram.com/skyreachid/" target="_blank" class="me-4 text-reset contact_icon">
            <i class="fab fa-instagram-square"></i>
          </a>
          <a href="https://www.linkedin.com/company/pt-skyreach/" target="_blank" class=" text-reset contact_icon">
            <i class="fab fa-linkedin"></i>
          </a>
        </div>
      </section>
  
    </footer>
  </template>
  <style scopped>
  @import url('@/assets/css/footer.css');
  </style>

<script src="@/assets/js/footer.js"></script>