<template>
  <nav class="navbar navbar-expand-md navbar-light fixed-top bg-white">
    <div class="container-fluid">
      <img src="@/assets/images/logo.svg" class="skychat_logo" alt="skychat logo">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav ms-auto p-2">
          <router-link class="nav-link ps-5 f-sm text-uppercase" to="/">{{ $t('Home') }}</router-link>
          <router-link class="nav-link ps-5 f-sm text-uppercase" to="/about">{{ $t('AboutUs') }}</router-link>
          <router-link class="nav-link ps-5 f-sm text-uppercase" to="/technology">{{ $t('Technology') }}</router-link>
          <router-link class="nav-link ps-5 f-sm text-uppercase" to="/industry">{{ $t('Industry') }}</router-link>
          <router-link class="nav-link ps-5 f-sm text-uppercase" to="/product">{{ $t('Product') }}</router-link>
          <router-link class="nav-link ps-5 f-sm text-uppercase" to="/publication">{{ $t('Publication') }}</router-link>
          <router-link class="nav-link ps-5 f-sm text-uppercase" to="/contact">{{ $t('Contact') }}</router-link>
          <div class="dropdown me-2">
            <button class="btn btn-sm dropdown-toggle nav-link ps-5 f-sm text-uppercase" type="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fas fa-language me-2"></i>{{ lang_label[$i18n.locale].label }}
            </button>
            <ul class="dropdown-menu dropdown-menu-end rounded-none dropdown_nav">
              <li @click="$i18n.locale = locale" v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`"
                :value="locale">
                <div style="cursor: pointer;" class="dropdown-item text-uppercase f-sm">{{ lang_label[locale].label }} 
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <router-view />
  <FooterView />
</template>
<script src="@/assets/js/appmain.js"></script>
<style>
@import url("@/assets/css/appmain.css");
</style>
