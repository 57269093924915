import FooterView from "@/components/FooterView.vue"

export default {
  name: "App",
  components: {
    FooterView
  },
  data: function () {
    return {
     lang_label : {
      en : {
        label : "English"
      },
      id : {
        label : "Indonesia"
      },
      zh : {
        label : "Chinese(中文)"
      }
    }
    };
  },

  methods: {
   
  }
};