<template>
  <main class=" w-100">
    <div class="containerimg" style="height: 95vh;">
      <img src="@/assets/images/industrilanding.png" alt="Snow" style="width:100%;height: 100%;object-fit: cover;">
      <div class="center-center text-center p-5">
        <h2 class="text-white text-uppercase">{{ $t("Industry") }}</h2>
        <p class="lead">
          {{ $t('Industrylanding_P') }}
        </p>
        <button type="button" class="btn btn-outline-light me-2 text-uppercase " style="border-radius: 0 !important;">
          <i class="fas fa-search me-2"></i>{{ $t('Explore_Btn') }}
        </button>
      </div>
    </div>
  </main>
  <section class="p-5  d-flex align-items-center justify-content-center">
    <div class="container">
      <h6 class=" text-start text-uppercase mb-5"><i class="fas fa-circle me-2 text-orange"></i><b
          class="text-lightblue">{{
            $t('Industry') }}</b></h6>
      <div class="bg-blue text-align-left mb-5">
        <div class="row">
          <div class="col-sm d-flex justify-content-end">
            <div class="containerimg">
              <img src="@/assets/images/gambar_G1.png" alt="Snow" class="w-100 h-100" style="object-fit: cover;">
              <div class="center-center text-start p-2">
                <h2 class="text-white">{{ $t('Industry_Tagline') }}</h2>
              </div>
            </div>
          </div>
          <div class="col-sm text-white  d-flex align-items-center justify-content-center p-5">
            <div>
              <p class="">{{ $t('Industry_P') }}</p>
              <button data-bs-toggle="modal" data-bs-target="#Industry1" type="button"
                class="btn  btn-sm btn-outline-light me-2 text-uppercase " style="border-radius: 0 !important;">
                {{ $t('IndustryExplore_Btn') }}
              </button>

            </div>
          </div>

        </div>

      </div>

      <div class="bg-blue text-align-left mb-5">
        <div class="row">
          <div class="col-sm d-flex justify-content-end">
            <div class="containerimg">
              <img src="@/assets/images/gambar_G2.png" alt="Snow" class="w-100 h-100" style="object-fit: cover;">
              <div class="center-center text-start p-2">
                <h2 class="text-white">{{ $t('Naturalrs_Title') }}</h2>
              </div>
            </div>
          </div>
          <div class="col-sm text-white  d-flex align-items-center justify-content-center p-5">
            <div>
              <p class="">{{ $t('Naturalrs_P') }}</p>
              <button data-bs-toggle="modal" data-bs-target="#Industry2" type="button" class="btn  btn-sm btn-outline-light me-2 text-uppercase "
                style="border-radius: 0 !important;">
                {{ $t('IndustryExplore_Btn') }}
              </button>

            </div>
          </div>

        </div>

      </div>

      <div class="bg-blue text-align-left mb-5">
        <div class="row">
          <div class="col-sm d-flex justify-content-end">
            <div class="containerimg">
              <img src="@/assets/images/gambar_G3.png" alt="Snow" class="w-100 h-100" style="object-fit: cover;">
              <div class="center-center text-start p-2">
                <h2 class="text-white">{{ $t('Media_Title') }}</h2>
              </div>
            </div>
          </div>
          <div class="col-sm text-white  d-flex align-items-center justify-content-center p-5">
            <div>
              <p class="">{{ $t('Media_P') }}</p>
              <button data-bs-toggle="modal" data-bs-target="#Industry3" type="button" class="btn  btn-sm btn-outline-light me-2 text-uppercase "
                style="border-radius: 0 !important;">
                {{ $t('IndustryExplore_Btn') }}
              </button>

            </div>
          </div>

        </div>

      </div>

      <div class="bg-blue text-align-left mb-5">
        <div class="row">
          <div class="col-sm d-flex justify-content-end">
            <div class="containerimg">
              <img src="@/assets/images/gambar_G4.png" alt="Snow" class="w-100 h-100" style="object-fit: cover;">
              <div class="center-center text-start p-2">
                <h2 class="text-white">{{ $t('Goverment_Title') }}</h2>
              </div>
            </div>
          </div>
          <div class="col-sm text-white  d-flex align-items-center justify-content-center p-5">
            <div>
              <p class="">{{ $t('Goverment_P') }}</p>
              <button data-bs-toggle="modal" data-bs-target="#Industry4" type="button" class="btn  btn-sm btn-outline-light me-2 text-uppercase "
                style="border-radius: 0 !important;">
                {{ $t('IndustryExplore_Btn') }}
              </button>

            </div>
          </div>

        </div>

      </div>

      <div class="bg-blue text-align-left mb-5">
        <div class="row">
          <div class="col-sm d-flex justify-content-end">
            <div class="containerimg">
              <img src="@/assets/images/gambar_G5.png" alt="Snow" class="w-100 h-100" style="object-fit: cover;">
              <div class="center-center text-start p-2">
                <h2 class="text-white">{{ $t('Aviation_Title') }}</h2>
              </div>
            </div>
          </div>
          <div class="col-sm text-white  d-flex align-items-center justify-content-center p-5">
            <div>
              <p class="">{{ $t('Aviation_P') }}</p>
              <button data-bs-toggle="modal" data-bs-target="#Industry5" type="button" class="btn  btn-sm btn-outline-light me-2 text-uppercase "
                style="border-radius: 0 !important;">
                {{ $t('IndustryExplore_Btn') }}
              </button>

            </div>
          </div>

        </div>

      </div>

    </div>
  </section>

  <!--INDUSTRY 1 MODAL-->
  <div class="modal  fade" id="Industry1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="Industry1Label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
      <div class="modal-content rounded-none">
        <div class="modal-header">
          <h1 class="modal-title fs-5 kebaikan text-blue" id="Industry1Label">{{ $t('Industry_Tagline') }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img src="@/assets/images/Industry_1.png" class="w-100" style="object-fit: contain;" alt="">
          <div class="w-100 p-5 pb-0 text-dark">
            <p class="fw-bold">
              {{ $t('Industry_suitable_for') }} :
            </p>
            <ul style="list-style: circle">
              <li>{{ $t('Industry_1_1') }}</li>
            </ul>

          </div>
          <div class="w-100 p-5 pt-0 pb-0 text-dark">
            <p class="fw-bold">
              {{ $t('Industry_skyreach_sol') }} :
            </p>
            <ul style="list-style: circle">
              <li>{{ $t('Industry_1_2_1') }}</li>
              <li>{{ $t('Industry_1_2_2') }}</li>
              <li>{{ $t('Industry_1_2_3') }}</li>
              <li>{{ $t('Industry_1_2_4') }}</li>
              <li>{{ $t('Industry_1_2_5') }}</li>
            </ul>

          </div>

        </div>
        <div class="modal-footer"></div>

      </div>
    </div>
  </div>




  <!--INDUSTRY 2 MODAL-->
  <div class="modal  fade" id="Industry2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="Industry2Label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
      <div class="modal-content rounded-none">
        <div class="modal-header">
          <h1 class="modal-title fs-5 kebaikan text-blue" id="Industry2Label">{{ $t('Naturalrs_Title') }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img src="@/assets/images/Industry_2.png" class="w-100" style="object-fit: contain;" alt="">
          <div class="w-100 p-5 pb-0 text-dark">
            <p class="fw-bold">
              {{ $t('Industry_suitable_for') }} :
            </p>
            <ul style="list-style: circle">
              <li>{{ $t('Industry_2_1') }}</li>
            </ul>

          </div>
          <div class="w-100 p-5 pt-0 pb-0 text-dark">
            <p class="fw-bold">
              {{ $t('Industry_skyreach_sol') }} :
            </p>
            <ul style="list-style: circle">
              <li>{{ $t('Industry_2_2_1') }}</li>
              <li>{{ $t('Industry_2_2_2') }}</li>
              <li>{{ $t('Industry_2_2_3') }}</li>
              <li>{{ $t('Industry_2_2_4') }}</li>
            </ul>

          </div>

        </div>
        <div class="modal-footer"></div>

      </div>
    </div>
  </div>


  <!--INDUSTRY 3 MODAL-->
  <div class="modal  fade" id="Industry3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="Industry3Label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
      <div class="modal-content rounded-none">
        <div class="modal-header">
          <h1 class="modal-title fs-5 kebaikan text-blue" id="Industry3Label">{{ $t('Media_Title') }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img src="@/assets/images/Industry_3.png" class="w-100" style="object-fit: contain;" alt="">
          <div class="w-100 p-5 pb-0 text-dark">
            <p class="fw-bold">
              {{ $t('Industry_suitable_for') }} :
            </p>
            <ul style="list-style: circle">
              <li>{{ $t('Industry_3_1') }}</li>
            </ul>

          </div>
          <div class="w-100 p-5 pt-0 pb-0 text-dark">
            <p class="fw-bold">
              {{ $t('Industry_skyreach_sol') }} :
            </p>
            <ul style="list-style: circle">
              <li>{{ $t('Industry_3_2_1') }}</li>
              <li>{{ $t('Industry_3_2_2') }}</li>
              <li>{{ $t('Industry_3_2_3') }}</li>
            </ul>

          </div>

        </div>
        <div class="modal-footer"></div>

      </div>
    </div>
  </div>



  <!--INDUSTRY 4 MODAL-->
  <div class="modal  fade" id="Industry4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="Industry4Label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
      <div class="modal-content rounded-none">
        <div class="modal-header">
          <h1 class="modal-title fs-5 kebaikan text-blue" id="Industry4Label">{{ $t('Goverment_Title') }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img src="@/assets/images/Industry_4.png" class="w-100" style="object-fit: contain;" alt="">
          <div class="w-100 p-5 pb-0 text-dark">
            <p class="fw-bold">
              {{ $t('Industry_suitable_for') }} :
            </p>
            <ul style="list-style: circle">
              <li>{{ $t('Industry_4_1_1') }}</li>
              <li>{{ $t('Industry_4_1_2') }}</li>
            </ul>

          </div>
          <div class="w-100 p-5 pt-0 pb-0 text-dark">
            <p class="fw-bold">
              {{ $t('Industry_skyreach_sol') }} :
            </p>
            <ul style="list-style: circle">
              <li>{{ $t('Industry_4_2_1') }}</li>
              <li>{{ $t('Industry_4_2_2') }}</li>
            </ul>
          </div>

        </div>
        <div class="modal-footer"></div>

      </div>
    </div>
  </div>



  <!--INDUSTRY 5 MODAL-->
  <div class="modal  fade" id="Industry5" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="Industry5Label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
      <div class="modal-content rounded-none">
        <div class="modal-header">
          <h1 class="modal-title fs-5 kebaikan text-blue" id="Industry5Label">{{ $t('Aviation_Title') }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img src="@/assets/images/Industry_5.png" class="w-100" style="object-fit: contain;" alt="">
          <div class="w-100 p-5 pb-0 text-dark">
            <p class="fw-bold">
              {{ $t('Industry_suitable_for') }} :
            </p>
            <ul style="list-style: circle">
              <li>{{ $t('Industry_5_1') }}</li>
            </ul>

          </div>
          <div class="w-100 p-5 pt-0 pb-0 text-dark">
            <p class="fw-bold">
              {{ $t('Industry_skyreach_sol') }} :
            </p>
            <ul style="list-style: circle">
              <li>{{ $t('Industry_5_2') }}</li>
            </ul>

          </div>

        </div>
        <div class="modal-footer"></div>

      </div>
    </div>
  </div>
</template>