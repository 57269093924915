<template>
  <main class=" w-100">
    <div class="containerimg" style="height: 85vh;">
      <img src="@/assets/images/mainlanding.png" alt="Snow" style="width:100%;height: 100%;object-fit: cover;">
      <div class="bottom-left text-start p-5">
        <h2 class="text-white text-uppercase" >Komunikasikan</h2>
        <h2 class="text-white text-uppercase kebaikan">Kebaikan</h2>
      </div>
    </div>
  </main>
  <section id="about" class="bg-body-tertiary d-flex align-items-center justify-content-center ">

    <div class="container p-2">
      <p class="lead text-center f-md mt-2">
        {{ $t('Summary') }}
      </p>

    </div>
  </section>

  <section id="beneficial" class="bg-white p-5  d-flex align-items-center justify-content-center">

    <div class="container">
      <h6 class="mb-5 text-start text-uppercase"><i class="fas fa-circle me-2 text-orange"></i><b
          class="text-lightblue">{{ $t('Tag_KeyValue') }}</b></h6>
      <div class="row">
        <div class="col-lg-4 text-center">
          <img src="@/assets/images/ekosistem.png" class="bd-placeholder-img rounded-circle" alt="Snow"
            style="width:140px;height:140px">
          <h5 class="fw-normal mt-2 text-blue"><b>{{ $t('Value_Title_1') }}</b></h5>
          <p>{{ $t('Value_P_1') }}</p>

        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4 text-center">
          <img src="@/assets/images/pengembangan.png" class="bd-placeholder-img rounded-circle" alt="Snow"
            style="width:140px;height:140px">

          <h5 class="fw-normal mt-2 text-blue"><b>{{ $t('Value_Title_2') }}</b></h5>
          <p>{{ $t('Value_P_2') }}</p>

        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4 text-center">
          <img src="@/assets/images/sepakat.png" class="bd-placeholder-img rounded-circle" alt="Snow"
            style="width:140px;height:140px">
          <h5 class="fw-normal mt-2 text-blue"><b>{{ $t('Value_Title_3') }}</b></h5>
          <p>{{ $t('Value_P_3') }}</p>
        </div>
      </div>
    </div>
  </section>


  <section class="p-5  d-flex align-items-center justify-content-center">
    <div class="container">
      <h6 class=" text-start text-uppercase"><i class="fas fa-circle me-2 text-orange"></i><b class="text-lightblue">{{
          $t('Industry') }}</b></h6>
      <div id="industry" class="bg-blue text-align-left ">
        <div class="row">
          <div class="col-sm d-flex justify-content-end">
            <div class="containerimg">
              <img src="@/assets/images/gambar_G1.png" class="w-100 h-100" alt="Snow" style="object-fit: cover;">
              <div class="center-center text-start p-2">
                <h2 class="text-white">{{ $t('Industry_Tagline') }}</h2>
              </div>
            </div>
          </div>
          <div class="col-sm text-white  d-flex align-items-center justify-content-center p-5">
            <div>
              <p class="">{{ $t('Industry_P') }}</p>
              <router-link  to="/industry"><button type="button"  class="btn  btn-sm btn-outline-light me-2 text-uppercase "
                style="border-radius: 0 !important;">
                {{ $t('Industry_Btn') }}
              </button></router-link>

            </div>
          </div>

        </div>

      </div>
    </div>
  </section>


  <section class="p-5  d-flex align-items-center justify-content-center">
    <div class="container">
      <h6 class=" text-start text-uppercase"><i class="fas fa-circle me-2 text-orange"></i><b class="text-lightblue">{{
          $t('Tag_Update') }}</b></h6>
      <div id="latest_project" class="bg-body-tertiary text-align-left ">
        <div class="row">
          <div class="col-sm d-flex justify-content-end">
            <iframe width="100%" src="https://www.youtube.com/embed/HtOLDbp-Zow?si=zwEAfGGB3eUgOVrb"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div class="col-sm text-dark  d-flex align-items-center justify-content-center p-5">
            <div>
              <p class="text-blue"><b>PRIMA ENERGI, Bawean Jawa Timur</b></p>
              <p class="">
                {{ $t('Update_P') }}
              </p>


            </div>
          </div>

        </div>

      </div>
    </div>
  </section>

  <section class="   align-items-center justify-content-center product_service">
    <div class="container">
      <h6 class="text-start text-uppercase pt-5"><i class="fas fa-circle me-2 text-orange"></i><b class="text-white">{{
          $t('Tag_Product') }}</b></h6>
      <div class=" pb-5 ">
        <div class="container">
          <div class="row p-5 row-cols-1 row-cols-sm-2 row-cols-md-5 g-2">
            <div class="col">
              <div class="card rounded-none border-blue">
                <div class="img-wrap">
                  <ul>
                    <li>
                      <img class="w-100" src="@/assets/images/product-img_1.png" />
                      <div class="img-description">
                        <router-link class="btn  btn-sm btn-outline-light me-2 text-uppercase " style="border-radius: 0 !important;"  to="/product">
                          {{ $t('Product_Btn') }}
                       </router-link>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-center">
                    <img style="width:95%;" src="@/assets/images/logo-skylink.png" alt="">
                  </div>

                </div>
              </div>
            </div>
            <div class="col">
              <div class="card rounded-none border-blue">
                <div class="img-wrap">
                  <ul>
                    <li>
                      <img class="w-100" src="@/assets/images/product-img_2.png" />
                      <div class="img-description">
                        <router-link class="btn  btn-sm btn-outline-light me-2 text-uppercase " style="border-radius: 0 !important;"  to="/product">
                          {{ $t('Product_Btn') }}
                       </router-link>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-center">
                    <img style="width:95%;" src="@/assets/images/logo-thereach.png" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card rounded-none border-blue">
                <div class="img-wrap">
                  <ul>
                    <li>
                      <img class="w-100" src="@/assets/images/product-img_3.png" />
                      <div class="img-description">
                        <router-link class="btn  btn-sm btn-outline-light me-2 text-uppercase " style="border-radius: 0 !important;"  to="/product">
                          {{ $t('Product_Btn') }}
                       </router-link>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-center">
                    <img style="width:95%;" src="@/assets/images/logo-skyway.png" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card rounded-none border-blue">
                <div class="img-wrap">
                  <ul>
                    <li>
                      <img class="w-100" src="@/assets/images/product-img_4.png" />
                      <div class="img-description">
                        <router-link class="btn  btn-sm btn-outline-light me-2 text-uppercase " style="border-radius: 0 !important;"  to="/product">
                          {{ $t('Product_Btn') }}
                       </router-link>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-center">
                    <img style="width:95%;" src="@/assets/images/logo-discovery.png" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card rounded-none border-blue">
                <div class="img-wrap">
                  <ul>
                    <li>
                      <img class="w-100" src="@/assets/images/product-img_5.png" />
                      <div class="img-description">
                        <router-link class="btn  btn-sm btn-outline-light me-2 text-uppercase " style="border-radius: 0 !important;"  to="/product">
                          {{ $t('Product_Btn') }}
                       </router-link>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-center">
                    <img style="width:95%;" src="@/assets/images/logo-sailink.png" alt="">
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


    </div>


  </section>

</template>

<script src="@/assets/js/home.js"></script>
