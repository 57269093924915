<template>
  <main class=" w-100">
    <div class="containerimg" style="height: 95vh;">
      <img src="@/assets/images/publiclanding.png" alt="Snow" style="width:100%;height: 100%;object-fit: cover;">
      <div class="center-center text-center p-5">
        <h2 class="text-white text-uppercase">{{ $t('Publication') }}</h2>
        <p class="lead">
          {{ $t("Publiclanding_P") }}
        </p>
        <button type="button" class="btn btn-outline-light me-2 text-uppercase " style="border-radius: 0 !important;">
          <i class="fas fa-search me-2"></i> {{ $t('Explore_Btn') }}
        </button>
      </div>
    </div>
  </main>

  <section class="p-5 bg-body-tertiary">
    <div class="container">
      <div class="row g-5">
        <div class="col-md-8">
          <h6 class="text-uppercase mb-2"><i class="fas fa-circle me-2 text-orange"></i><b class="text-lightblue">{{
          $t('LatestUp') }}</b></h6>

          <article class="blog-post">
            <h4 class="display-5 link-body-emphasis mb-1">{{ $t('ArtMain') }}</h4>
            <p class="blog-post-meta">February 9, 2022</p>
            <p class="text-blue">{{ $t("ArtMain_Sum") }}</p>
            <hr>
            <p style="text-align: justify;">{{ $t('ArtMain_P') }}</p>
          </article>
        </div>

        <div class="col-md-4">
          <div class="position-sticky" style="top: 2rem;">
            <div>
              <h6 class="text-uppercase mb-2"><i class="fas fa-circle me-2 text-orange"></i><b class="text-lightblue">{{
          $t('Recent') }}</b></h6>
              <ul class="list-unstyled">
                <li>
                  <a class="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none border-top"
                    href="#">
                    <div style="width: 100%;height: 96px">
                      <img src="@/assets/images/news1.png" class="h-100 w-100" style="object-fit: cover;" alt="">
                    </div>
                    <div class="col-lg-8">
                      <h6 class="mb-0">{{ $t('Art1') }}</h6>
                      <small class="text-body-secondary">February 10, 2022</small>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none border-top"
                    href="#">
                    <div style="width: 100%;height: 96px">
                      <img src="@/assets/images/news2.png" class="h-100 w-100" style="object-fit: cover;" alt="">
                    </div>

                    <div class="col-lg-8">
                      <h6 class="mb-0">{{ $t('Art2') }}</h6>
                      <small class="text-body-secondary">January 10, 2022</small>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none border-top"
                    href="#">
                    <div style="width: 100%;height: 96px">
                      <img src="@/assets/images/news3.png" class="h-100 w-100" style="object-fit: cover;" alt="">
                    </div>
                    <div class="col-lg-8">
                      <h6 class="mb-0">{{ $t('Art3') }}</h6>
                      <small class="text-body-secondary">April 8, 2022</small>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <h6 class="text-uppercase mb-2 mt-2"><i class="fas fa-circle me-2 text-orange"></i><b
                class="text-lightblue">{{ $t('MoreArt') }}</b></h6>
            <div class="p-4 pt-0">

              <ol class="list-unstyled mb-0">
                <li><a href="#">March 2021</a></li>
                <li><a href="#">February 2021</a></li>
                <li><a href="#">January 2021</a></li>
                <li><a href="#">December 2020</a></li>
                <li><a href="#">November 2020</a></li>

              </ol>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="p-5  bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">
      <h6 class="text-uppercase"><i class="fas fa-circle me-2 text-orange"></i><b class="text-lightblue">{{
          $t('Youhaveto') }}</b>
      </h6>
      <div class="album py-2 bg-body-tertiary">
        <div class="container">

          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5 g-3">
            <div style="cursor: pointer;" class="col" data-bs-toggle="modal" data-bs-target="#know1">
              <div class="card rounded-none">
                <img src="@/assets/images/pubknow1.png" style="object-fit: cover;" class="w-100" alt="">
                <div class="card-body">
                  <h5 class="text-blue">{{ $t('Know1') }}</h5>
                </div>
              </div>
            </div>
            <div style="cursor: pointer;" class="col" data-bs-toggle="modal" data-bs-target="#know2">
              <div class="card rounded-none">
                <img src="@/assets/images/pubknow2.png" style="object-fit: cover;" class="w-100" alt="">
                <div class="card-body">
                  <h5 class="text-blue">{{ $t('Know2') }}</h5>
                </div>
              </div>
            </div>
            <div style="cursor: pointer;" class="col" data-bs-toggle="modal" data-bs-target="#know3">
              <div class="card rounded-none">
                <img src="@/assets/images/pubknow3.png" style="object-fit: cover;" class="w-100" alt="">
                <div class="card-body">
                  <h5 class="text-blue">{{ $t('Know3') }}</h5>
                </div>
              </div>
            </div>
            <div style="cursor: pointer;" class="col" data-bs-toggle="modal" data-bs-target="#know4">
              <div class="card rounded-none">
                <img src="@/assets/images/pubknow4.png" style="object-fit: cover;" class="w-100" alt="">
                <div class="card-body">
                  <h5 class="text-blue">{{ $t('Know4') }}</h5>
                </div>
              </div>
            </div>
            <div style="cursor: pointer;" class="col" data-bs-toggle="modal" data-bs-target="#know5">
              <div class="card rounded-none">
                <img src="@/assets/images/pubknow5.png" style="object-fit: cover;" class="w-100" alt="">
                <div class="card-body">
                  <h5 class="text-blue">{{ $t('Know5') }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

  <!--KNOW 1 MODAL-->
  <div class="modal fade" id="know1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="know1Label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
      <div class="modal-content rounded-none">
        <div class="modal-header">
          <h1 class="modal-title fs-5 kebaikan text-blue" id="know1Label">{{ $t('Know1') }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="w-100 bg-blue p-5 text-white">
            {{ $t('Know1_1') }}
          </div>
          <img src="@/assets/images/know1_1.png" class="w-100" style="object-fit: contain;" alt="">
          <div class="w-100 bg-blue p-5 text-white">
          {{ $t('Know1_2') }}
          </div>
          <img src="@/assets/images/know1_2.png" class="w-100" style="object-fit: cover;" alt="">

        </div>
        <div class="modal-footer"></div>

      </div>
    </div>
  </div>


  <!--KNOW 2 MODAL-->
  <div class="modal fade" id="know2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="know2Label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
      <div class="modal-content rounded-none">
        <div class="modal-header">
          <h1 class="modal-title fs-5 kebaikan text-blue" id="know2Label">{{ $t('Know2') }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="w-100 bg-blue p-5 text-white">
           {{$t('Know2_1')}}
          </div>
          <img src="@/assets/images/know2_1.png" class="w-100" style="object-fit: contain;" alt="">
          <div class="w-100 bg-blue p-5 text-white">
            {{$t('Know2_2')}}
            <ul style="list-style: circle">
              <li>
                {{$t('Know2_3')}}
              </li>
              <li> {{$t('Know2_4')}}</li>
              <li> {{$t('Know2_5')}}</li>
            </ul>
          </div>
        </div>
        <div class="modal-footer"></div>

      </div>
    </div>
  </div>


  <!--KNOW 3 MODAL-->
  <div class="modal  fade" id="know3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="know3Label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
      <div class="modal-content rounded-none">
        <div class="modal-header">
          <h1 class="modal-title fs-5 kebaikan text-blue" id="know3Label">{{ $t('Know3') }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="w-100 bg-blue p-5 text-white">
            <p>
           {{ $t('Know3_1') }}

            </p>
            <ul style="list-style: circle">
              <li>{{ $t('Know3_1_1') }}</li>
              <li>{{ $t('Know3_1_2') }}</li>
              <li>{{ $t('Know3_1_3') }}</li>
              <li>{{ $t('Know3_1_4') }}</li>
            </ul>
            <img src="@/assets/images/know3_1.png" class="w-100" style="object-fit: contain;" alt="">
          </div>

          <div class="w-100 mt-5 bg-blue p-5 text-white">
            <p>
             {{ $t('Know3_2') }}
            </p>

            <img src="@/assets/images/know3_2.png" class="w-100" style="object-fit: contain;" alt="">
          </div>


          

        </div>
        <div class="modal-footer"></div>

      </div>
    </div>
  </div>

  <!--KNOW 4 MODAL-->
  <div class="modal fade" id="know4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="know4Label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
      <div class="modal-content rounded-none">
        <div class="modal-header">
          <h1 class="modal-title fs-5 kebaikan text-blue" id="know4Label">{{ $t('Know4')}}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="w-100 bg-blue p-5 text-white">
            <p>
             {{ $t('Know4_1') }}
            </p>
            <p> {{ $t('Know4_1_1') }}</p>
            <p> {{ $t('Know4_1_2') }}</p>
            <ul style="list-style: circle">
              <li>{{ $t('Know4_1_3') }}</li>
              <li>{{ $t('Know4_1_4') }}</li>
              <li>{{ $t('Know4_1_5') }}</li>
            </ul>

            <p>{{ $t('Know4_1_6') }}</p>
            <ul style="list-style: circle;">
              <li>{{ $t('Know4_1_7') }}</li>
              <li>{{ $t('Know4_1_8') }}</li>
              <li>{{ $t('Know4_1_9') }}</li>
              <li>{{ $t('Know4_1_10') }}</li>
              <li>{{ $t('Know4_1_11') }}</li>
            </ul>








          </div>
          <img src="@/assets/images/know4_1.png" class="w-100" style="object-fit: contain;" alt="">
          <div class="w-100 bg-blue p-5 text-white">
           {{ $t('Know4_2') }}
          </div>
          <img src="@/assets/images/know4_2.png" class="w-100 mt-2" style="object-fit: cover;" alt="">

        </div>
        <div class="modal-footer"></div>

      </div>
    </div>
  </div>

  <!--KNOW 5 MODAL-->
  <div class="modal fade" id="know5" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="know5Label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
      <div class="modal-content rounded-none">
        <div class="modal-header">
          <h1 class="modal-title fs-5 kebaikan text-blue" id="know5Label">{{$t('Know5')}}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="w-100 bg-blue p-5 text-white">
           {{ $t('Know5_1') }}
          </div>
          <img src="@/assets/images/know5_1.png" class="w-100" style="object-fit: contain;" alt="">
          <div class="w-100 bg-blue p-5 text-white">
            <p>{{ $t('Know5_2') }}</p>
            <p class="kebaikan">Ku-Band (HTS Beams)</p>
          </div>
          <img src="@/assets/images/know5_2.png" class="w-100" style="object-fit: cover;" alt="">

        </div>
        <div class="modal-footer"></div>

      </div>
    </div>
  </div>
  
</template>