<template>
  <main class=" w-100">
    <div class="containerimg" style="height: 95vh;">
      <img src="@/assets/images/produklanding.png" alt="Snow" style="width:100%;height: 100%;object-fit: cover;">
      <div class="center-center text-center p-5">
        <h2 class="text-white text-uppercase">{{ $t('Product') }}</h2>
        <p class="lead">
          {{ $t('Productlanding_P') }}
        </p>
        <button type="button" class="btn btn-outline-light me-2 text-uppercase " style="border-radius: 0 !important;">
          <i class="fas fa-search me-2"></i> {{ $t('Explore_Btn') }}
        </button>
      </div>
    </div>
  </main>

  <section class="p-5 pb-0 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">
      <div class=" text-align-left ">
        <div class="row">
          <div class="col-sm  d-flex align-items-center justify-content-center p-5">
            <div>
              <div class="d-flex align-items-center mb-2">
                <i class="fas fa-circle me-2 text-orange "></i>
                <img style="width:150px;object-fit: cover" src="@/assets/images/logo-skylink.png" alt="">
              </div>
              <p style="text-align: justify;
  text-justify: inter-word;">{{ $t('Prod1_P') }}</p>
            </div>
          </div>
          <div class="col-sm d-flex justify-content-end">
            <img src="@/assets/images/prod1.png" alt="Snow" style="width:100%;height: 100%;object-fit: contain;">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="p-5 pt-0 pb-0 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">
      <div class=" text-align-left ">
        <div class="row">
          <div class="col-sm d-flex justify-content-end">
            <img src="@/assets/images/prod2.png" alt="Snow" style="width:100%;height: 100%;object-fit: contain;">
          </div>
          <div class="col-sm  d-flex align-items-center justify-content-center p-5">
            <div>
              <div class="d-flex align-items-center mb-2">
                <i class="fas fa-circle me-2 text-orange "></i>
                <img style="width:150px;object-fit: cover" src="@/assets/images/logo-skyway.png" alt="">
              </div>
              <p style="text-align: justify;
  text-justify: inter-word;">{{ $t('Prod2_P') }}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <section class="p-5 pt-0 pb-0 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">
      <div class=" text-align-left ">
        <div class="row">
          <div class="col-sm  d-flex align-items-center justify-content-center p-5">
            <div>
              <div class="d-flex align-items-center mb-2">
                <i class="fas fa-circle me-2 text-orange "></i>
                <img style="width:150px;object-fit: cover" src="@/assets/images/logo-discovery.png" alt="">
              </div>
              <p style="text-align: justify;
  text-justify: inter-word;">{{ $t('Prod3_P') }}</p>
            </div>
          </div>
          <div class="col-sm d-flex justify-content-end">
            <img src="@/assets/images/prod3.png" alt="Snow" style="width:100%;height: 100%;object-fit: contain;">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="p-5 pt-0 pb-0 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">
      <div class=" text-align-left ">
        <div class="row">
          <div class="col-sm d-flex justify-content-end">
            <img src="@/assets/images/prod4.png" alt="Snow" style="width:100%;height: 100%;object-fit: contain;">
          </div>
          <div class="col-sm  d-flex align-items-center justify-content-center p-5">
            <div>
              <div class="d-flex align-items-center mb-2">
                <i class="fas fa-circle me-2 text-orange "></i>
                <img style="width:150px;object-fit: cover" src="@/assets/images/logo-sailink.png" alt="">
              </div>
              <p style="text-align: justify;
  text-justify: inter-word;">{{ $t('Prod4_P') }}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <section class="p-5 pt-0 pb-0 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">
      <div class=" text-align-left ">
        <div class="row">
          <div class="col-sm  d-flex align-items-center justify-content-center p-5">
            <div>
              <div class="d-flex align-items-center mb-2">
                <i class="fas fa-circle me-2 text-orange "></i>
                <img style="width:150px;object-fit: cover" src="@/assets/images/logo-navigator.png" alt="">
              </div>
              <p style="text-align: justify;
  text-justify: inter-word;">{{ $t('Prod5_P') }}</p>
            </div>
          </div>
          <div class="col-sm d-flex justify-content-end">
            <img src="@/assets/images/prod5.png" alt="Snow" style="width:100%;height: 100%;object-fit: contain;">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="p-5 pt-0 pb-0 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">
      <div class=" text-align-left ">
        <div class="row">
          <div class="col-sm  d-flex align-items-center justify-content-center p-5">
            <div>
              <div class="d-flex align-items-center mb-2">
                <i class="fas fa-circle me-2 text-orange "></i>
                <img style="width:150px;object-fit: cover" src="@/assets/images/logo-navplus.png" alt="">
              </div>
              <p style="text-align: justify;
  text-justify: inter-word;">{{ $t('Prod6_P') }}</p>
            </div>
          </div>
          <div class="col-sm d-flex justify-content-center">
            <img src="@/assets/images/prod6.png" alt="Snow" style="width:50%;height: 100%;object-fit: contain;">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="p-5   bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">
      <div class=" text-align-left ">
        <div class="row">


          <div class="col-sm d-flex justify-content-center">
            <div class="">
              <div class="d-block pb-5 ">
                <div>
                  <div class="d-flex align-items-center mb-2">
                    <i class="fas fa-circle me-2 text-orange "></i>
                    <img style="width:150px;object-fit: cover" src="@/assets/images/logo-thereach.png" alt="">
                  </div>
                  <p style="text-align: justify;
  text-justify: inter-word;">{{ $t('Prod7_P') }}</p>


                </div>
              </div>
              <div class="d-block ">
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/esjDWLp4t1A?si=XG3fDZsA2d1IsVIN"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
            </div>
          </div>


          <div class="col-sm d-flex justify-content-center">
            <div class="h-100">
              <img class="d-block pb-5 " src="@/assets/images/prod7_1.png" alt="Snow"
                style="width:100%;height: 50%;object-fit: cover;">
              <img class="d-block " src="@/assets/images/prod7_2.png" alt="Snow"
                style="width:100%;height:50%;object-fit: cover;">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="p-5 pt-2 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">
    
        <div class="row">
          <div class="col-sm p-2  d-flex align-items-center justify-content-center">
            <img src="@/assets/images/prod8_1.png" alt="Snow" style="width:100%;height: 100%;object-fit: contain;">
          </div>
          <div class="col-sm p-2 d-flex align-items-center justify-content-center">
            <img src="@/assets/images/prod8_2.png" alt="Snow" style="width:100%;height: 100%;object-fit: contain;">
          </div>
        </div>
    
    </div>
  </section>



</template>