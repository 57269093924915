import FooterView from "@/components/FooterView.vue"


export default {
  name: "Home",
  components: {
    FooterView
  },
  data: function () {
    return {
     
    };
  },

  methods: {
   
  }
};