<template>
  <main class=" w-100">
    <div class="containerimg" style="height: 95vh;">
      <img src="@/assets/images/technolanding.png" alt="Snow" style="width:100%;height: 100%;object-fit: cover;">
      <div class="center-center text-center p-5">
        <h2 class="text-white text-uppercase">{{ $t('Technology') }}</h2>
        <p class="lead">
          {{ $t('Technolanding_P') }}
        </p>
        <button type="button" class="btn btn-outline-light me-2 text-uppercase " style="border-radius: 0 !important;">
          <i class="fas fa-search me-2"></i> {{ $t('Explore_Btn') }}
        </button>
      </div>
    </div>
  </main>
  <section class="p-5 pb-0 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">

      <div class=" text-align-left ">
        <div class="row">
          <div class="col-sm  d-flex align-items-center justify-content-center p-5">
            <div>
              <h6 class="text-uppercase"><i class="fas fa-circle me-2 text-orange "></i><b class="text-lightblue">{{
          $t('Technology') }}</b>
              </h6>
              <p style="text-align: justify;
  text-justify: inter-word;">{{ $t('Tech1_P') }}</p>
            </div>
          </div>
          <div class="col-sm d-flex justify-content-end">

            <img src="@/assets/images/tech1.png" alt="Snow" style="width:100%;height: 100%;object-fit: contain;">

          </div>


        </div>

      </div>
    </div>
  </section>

  <section class="p-5 pt-0 pb-0 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">

      <div class=" text-align-left ">
        <div class="row">
          <div class="col-sm  d-flex align-items-center justify-content-center p-5">
            <div>
              <h6 class="text-uppercase"><i class="fas fa-circle me-2 text-orange"></i><b class="text-lightblue">{{
          $t('Infrastructure') }}</b>
              </h6>
              <p style="text-align: justify;
  text-justify: inter-word;">{{ $t("Tech2_P") }}
              </p>
            </div>
          </div>
          <div class="col-sm d-flex justify-content-end">

            <img src="@/assets/images/tech2.png" alt="Snow" style="width:100%;height: 100%;object-fit: contain;">

          </div>




        </div>

      </div>
    </div>
  </section>

  <section class="p-5 pt-0 pb-0 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">

      <div class=" text-align-left ">
        <div class="row">
          <div class="col-sm  d-flex align-items-center justify-content-center p-5">
            <div>
              <h6 class="text-uppercase"><i class="fas fa-circle me-2 text-orange"></i><b class="text-lightblue">{{
          $t('InfrastructureTech') }}</b>
              </h6>
              <p style="text-align: justify;
  text-justify: inter-word;">{{ $t('Tech3_P1') }}</p>
              <ul style="list-style-type: circle;">
                <li>
                  SCPC (Single Channel Per Carrier)</li>
                <li>MF-TDMA (Multi Frequency – Time Division Multiple Access)</li>
                <li>
                  MCPC (Multiple Channel Per Carrier)
                </li>
              </ul>
              <p style="text-align: justify;
  text-justify: inter-word;">{{ $t('Tech3_P2') }}</p>
              <ul style="list-style-type: circle;">
                <li>
                  iDirect Technologies Inc. (www.idirect.net)</li>
                <li>Newtec (www.newtec.eu)</li>
                <li>
                  Comtech EF Data (www.comtechefdata.com)
                </li>
                <li>
                  SatPath Inc. (www.satpath.com)
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm d-flex justify-content-end">

            <img src="@/assets/images/tech3.png" alt="Snow" style="width:100%;height: 100%;object-fit: contain;">

          </div>


        </div>

      </div>
    </div>
  </section>

  <section class="p-5 pt-0 bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">

      <div class=" text-align-left ">
        <div class="row">
          <div class="col-sm  d-flex align-items-center justify-content-center p-5">
            <div>
              <h6 class="text-uppercase"><i class="fas fa-circle me-2 text-orange"></i><b class="text-lightblue">{{
                  $t('CustResp') }}</b>
              </h6>
              <p style="text-align: justify;
  text-justify: inter-word;">{{ $t('Tech4_P') }}</p>
            </div>
          </div>
          <div class="col-sm d-flex justify-content-end">

            <img src="@/assets/images/tech4.png" alt="Snow" style="width:100%;height: 100%;object-fit: cover;">

          </div>


        </div>

      </div>
    </div>
  </section>



</template>