
export default {
    name: "Footer",
    data: function () {
        return {
            year: "2012"
        };
    },

    mounted() {
        const d = new Date();
        let year = d.getFullYear();
        this.year = year
    },

    methods: {

    }
};