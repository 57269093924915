<template>
  <main class=" w-100">
    <div class="containerimg" style="height: 95vh;">
      <img src="@/assets/images/kontaklanding.png" alt="Snow" style="width:100%;height: 100%;object-fit: cover;">
      <div class="center-center text-center p-5">
        <h2 class="text-white text-uppercase">{{ $t('ContactUs') }}</h2>
        <p class="lead">
          {{ $t('Contactlanding_P') }}
        </p>
        <button type="button" class="btn btn-outline-light me-2 text-uppercase " style="border-radius: 0 !important;">
          <i class="fas fa-search me-2"></i> {{ $t('Explore_Btn') }}
        </button>
      </div>
    </div>
  </main>
  <section class="p-5  bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">

      <div class="album py-2 bg-body-tertiary">
        <div class="container">

          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 g-3">
            <div class="col d-flex align-items-center">
              <div>
                <h3 class="text-uppercase kebaikan text-blue">{{
          $t('HubUs') }}
                </h3>
                <p class="lead">{{ $t('Contact_content') }}</p>
              </div>
            </div>

            <div class="col  d-flex align-items-center justify-content-center">
              <div class="card w-100 p-5 rounded-none">
                <form>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">{{ $t('Name') }}</label>
                    <input type="text" class="form-control" id="contactName" >
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">{{$t('Email')}}</label>
                    <input type="email" class="form-control" id="contactEmail" >
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">{{ $t('PhoneNumber') }}</label>
                    <input type="text" class="form-control" id="exampleInputPassword1">
                  </div>

                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">{{$t('Message')}}</label>
                    <textarea class="form-control" ></textarea>
                    
                  </div>
            
                  <button type="submit" class="btn btn-primary rounded-none text-uppercase">{{ $t('Send') }}</button>
                </form>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>
  </section>
  <section class="p-5  bg-body-tertiary d-flex align-items-center justify-content-center">
    <div class="container">
      <h6 class="text-uppercase"><i class="fas fa-circle me-2 text-orange"></i><b class="text-lightblue">{{
          $t('Ourloct') }}</b>
      </h6>
      <div class="album py-2 bg-body-tertiary">
        <div class="container">

          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div class="col">
              <div class="card rounded-none">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3966.826569495215!2d106.846311!3d-6.153977!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f545e198882d%3A0x7b3e48cf88358dca!2sSkyreach%20PT.!5e0!3m2!1sen!2sid!4v1713416124818!5m2!1sen!2sid"
                  width="100%" height="150" style="border:0;" allowfullscreen="true" loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
                <div class="card-body">
                  <h5 class="text-blue">Skyreach Head Office Jakarta</h5>
                  <h6 class="text-orange">Kemayoran - Jakarta</h6>
                  <p>Hermina Tower, 20th Floor, Jl. HBR Motik No.4, RW.10,
                    Gn. Sahari Sel, Kec. Kemayoran, Kota Jakarta Pusat,
                    Daerah Khusus Ibukota Jakarta 10610</p>

                </div>
              </div>
            </div>

            <div class="col">
              <div class="card rounded-none">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.134944936191!2d107.15572290844511!3d-6.37657539358704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69994e8c65bf37%3A0x48f909dd479f370d!2sThereach%20Teleport!5e0!3m2!1sen!2sid!4v1713416445395!5m2!1sen!2sid"
                  width="100%" height="150" style="border:0;" allowfullscreen="true" loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
                <div class="card-body">
                  <h5 class="text-blue">Thereach Teleport</h5>
                  <h6 class="text-orange">Cikarang - Jawa Barat</h6>
                  <p>Gg. Sakum, Cicau, Kec. Cikarang Pusat,
                    Kabupaten Bekasi, Jawa Barat 17530</p>
                  <br><br>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card rounded-none">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.019557230344!2d104.0067562084061!3d1.1465085988376886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31d989f70f903a49%3A0x261cd0efcb31da6c!2sPT.%20SKYREACH%20-%20Batam%20Branch!5e0!3m2!1sen!2sid!4v1713416596096!5m2!1sen!2sid"
                  width="100%" height="150" style="border:0;" allowfullscreen="true" loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
                <div class="card-body">
                  <h5 class="text-blue">Skyreach Batam Branch</h5>
                  <h6 class="text-orange">Kota Batam - Kep. Riau</h6>
                  <p>Komplek Ruko Palm Spring Blok A IV No.11
                    Kel. Taman Baloi, Kec. Batam kota - Kota Batam 29432</p>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

</template>